const getTextWidth = (canvas, text, font) => {
  const coefficient = 1 // 1.1725 // 1.145
  const context = canvas.getContext("2d")
  context.font = font

  const metrics = context.measureText(text.toUpperCase())

  // TODO: для теста можно вывести на страницу
  // context.fillText(text.toUpperCase(), 10, 40)
  // canvas.style.position = "fixed"
  // canvas.style.top = "510px"
  // canvas.style.left = "50%"
  // canvas.style.transform = "translateX(-50%)"
  // canvas.style.zIndex = "10000"
  // document.body.appendChild(canvas)

  return metrics.width * coefficient
}

const getCssStyle = (element, prop) => {
  return window ? window.getComputedStyle(element, null).getPropertyValue(prop) : null
}

const getCanvasFont = (el) => {
  const fontWeight = getCssStyle(el, "font-weight") || "normal"
  const fontSize = getCssStyle(el, "font-size") || "16px"
  const fontFamily = getCssStyle(el, "font-family") || "Times New Roman"

  return `${fontWeight} ${fontSize} ${fontFamily}`
}

export { getTextWidth, getCanvasFont }
