import { IBookEditor, IProductConfigurationMapper } from "../@types"
import { CLIENT_ALBUM_ID } from "@app/features/client-upload/constants"

class BookMapper implements IProductConfigurationMapper {
  constructor(private configuration: string) {}

  get backEndConfiguration() {
    const configuration: IBookEditor = JSON.parse(this.configuration)
    return {
      clientAlbum: configuration?.clientAlbum?.id || localStorage.getItem(CLIENT_ALBUM_ID),
      productOptionValues: [
        {
          category: "COVER_COLOR",
          value: configuration.productOptionValues.color,
        },
      ],
      productConfiguration: {
        version: configuration.productConfiguration.version,
        cover: {
          photo: {
            id: configuration.productConfiguration.cover?.photo.id || null,
            offset: configuration.productConfiguration.cover?.photo.offset,
            type: configuration.productConfiguration.cover?.photo?.type || "photo",
          },
        },
        pages: configuration.productConfiguration.pages.map((page) => ({
          style: page.style,
          photos: page.photos.map((photo) => ({
            id: photo.id,
            type: photo?.type,
            offset: {
              x: parseInt(`${photo.offset.x}`),
              y: parseInt(`${photo.offset.y}`),
            },
          })),
        })),
      },
    }
  }

  get clientConfiguration(): Object {
    let clientConfiguration = JSON.parse(this.configuration)

    clientConfiguration.clientAlbum = {
      id: clientConfiguration?.clientAlbum?.id,
    }

    clientConfiguration.productOptionValues = {
      color: clientConfiguration.productOptionValues.filter((item) => {
        return item.productOptionValue.option.category.handle === "COVER_COLOR"
      })[0].productOptionValue.value,
      // сменили с optionValues на purchaseProductOptionValues (order/[orderId]/edit.tsx]), там другая структура данных
      // clientConfiguration.productOptionValues.filter((item) => item.option.category.handle === "COVER_COLOR")[0].value
      pages: clientConfiguration.productConfiguration.pages.length,
    }

    clientConfiguration.productConfiguration.version = clientConfiguration.productConfiguration?.version || 0

    clientConfiguration.productConfiguration.cover = {
      photo: {
        id: clientConfiguration.productConfiguration.cover.photo.id,
        offset: clientConfiguration.productConfiguration.cover.photo.offset,
        type: clientConfiguration.productConfiguration.cover.photo?.type,
      },
    }

    return clientConfiguration
  }
}

export { BookMapper }
