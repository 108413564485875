/**
 * Description
 * @param {any} galleryId:string
 * @param {any} localStorage
 * @returns {any}
 */
import { ILocalStorageService } from "@book-editor-v2/@types"

const ERRORS: { [key: string]: string } = {
  GALLERY_ID_UNDEFINED: "Gallery id undefined",
  LOCAL_STORAGE_UNDEFINED: "localStorage undefined",
}

const STORAGE_VERSION: number = 7

class LocalStorageService implements ILocalStorageService {
  localStorageKey: string
  localStorage: any

  constructor(productName: string, galleryId: string, pages: number | null, localStorage) {
    if (typeof galleryId !== "string") throw new Error(ERRORS.GALLERY_ID_UNDEFINED)
    if (typeof localStorage === "undefined") throw new Error(ERRORS.LOCAL_STORAGE_UNDEFINED)

    this.localStorageKey = !!pages
      ? `${productName}${galleryId}_${pages}-v${STORAGE_VERSION}`
      : `${productName}${galleryId}-v${STORAGE_VERSION}`
    this.localStorage = localStorage
  }

  clearOldVersions() {
    for (let deletableVersion = 0; (deletableVersion += 1); deletableVersion < STORAGE_VERSION) {
      this.removeByVersion(deletableVersion)
    }
  }

  removeByVersion(version: number) {
    const deletableStorageKey = this.localStorageKey.replace(`-v${STORAGE_VERSION}`, `-v${version}`)
    this.localStorage.removeItem(deletableStorageKey)
  }

  removeCurrentVersion() {
    this.localStorage.removeItem(this.localStorageKey)
  }

  hasSaved(): boolean {
    return !!this.localStorage.getItem(this.localStorageKey)
  }

  load(): string {
    return this.localStorage.getItem(this.localStorageKey)
  }

  save(json: string) {
    this.localStorage.setItem(this.localStorageKey, json)
  }
}

export { LocalStorageService, ERRORS, STORAGE_VERSION }
