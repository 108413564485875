import { IOffset } from "@book-editor-v2/@types"
import { makeAutoObservable } from "mobx"

/** Класс представляющий сдвиг фотографии */
export class Offset implements IOffset {
  x: number
  y: number

  /**
   * Создать сдвиг
   * @param {x} горизонтальный сдвиг
   * @param {y} вертикальный сдвиг
   */
  constructor(x: number = 0, y: number = 0) {
    this.x = x
    this.y = y

    makeAutoObservable(this)
  }

  convertToPixels(coordinates, imageSize, percents): { x: number; y: number } {
    return {
      x: (imageSize.width / 100) * percents.x,
      y: (imageSize.height / 100) * percents.y,
    }
  }

  convertToPercents(coordinates, imageSize): { x: number; y: number } {
    const draggableOffset = {
      x: ((coordinates.width - imageSize.width) / 2) * -1,
      y: ((coordinates.height - imageSize.height) / 2) * -1,
    }

    const currentOffset = {
      x: coordinates.left - draggableOffset.x,
      y: coordinates.top - draggableOffset.y,
    }

    return {
      x: (currentOffset.x / imageSize.width) * 100 * -1,
      y: (currentOffset.y / imageSize.height) * 100 * -1,
    }
  }

  setFromPixels(x, y, containerSize) {
    this.x = (x / containerSize.width) * 100 * -1
    this.y = (y / containerSize.height) * 100 * -1
  }

  /**
   * Установить новый сдвиг
   * @param {x} горизонтальный сдвиг
   * @param {y} вертикальный сдвиг
   */
  set(x, y) {
    this.x = x
    this.y = y
  }

  get() {}
}
