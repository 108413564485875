import create from "zustand"

interface BigWeightsModalState {
  isBigWeightsModalVisible: boolean
  album: Record<string, any>
  showBigWeightsModal: (album: Record<string, any>) => void
  hideBigWeightsModal: () => void
}

const useBigWeightsModalStore = create<BigWeightsModalState>((set) => ({
  isBigWeightsModalVisible: false,
  album: {},

  showBigWeightsModal: (album) => set({ album, isBigWeightsModalVisible: true }),

  hideBigWeightsModal: () => set({ isBigWeightsModalVisible: false, album: {} }),
}))

interface BigWeightsState {
  bigWeightImages: any[]
  photosWithoutBigWeights: any[]
  setBigWeightImages: (images: any[]) => void
  setPhotosWithoutBigWeights: (photos: any[]) => void
  resetBigWeights: () => void
}

const useBigWeightsStore = create<BigWeightsState>((set) => ({
  bigWeightImages: [],
  photosWithoutBigWeights: [],

  setBigWeightImages: (images) => set({ bigWeightImages: images }),

  setPhotosWithoutBigWeights: (photos) => set({ photosWithoutBigWeights: photos }),

  resetBigWeights: () =>
    set({
      bigWeightImages: [],
      photosWithoutBigWeights: [],
    }),
}))

export { useBigWeightsModalStore, useBigWeightsStore }
