import { IGalleryPhoto } from "@book-editor-v2/@types"

class GalleryPhotos {
  photos: IGalleryPhoto[]

  constructor() {}

  set(photos: any[]) {
    this.photos = photos
  }

  getById(photo_id: string): null | IGalleryPhoto {
    try {
      return this.photos.find((photo) => photo.id === photo_id)
    } catch (error) {
      console.log(
        `GalleryPhotos: Ошибка во время получения фотографии с идентификатором ${photo_id} из GalleryPhotos`,
        error
      )
      console.log("GalleryPhotos: Текущий массив photos:", this.photos)
      return null
    }
  }
}

export default new GalleryPhotos()
