import {
  ICaption,
  ICover,
  IPage,
  IPhoto,
  IProductConfiguration,
  PageStyle,
  Quality,
  TPhotoDraggableData,
} from "@book-editor-v2/@types"
import { Caption } from "@book-editor-v2/products/mini-book/store/caption"
import { arrayMove } from "@dnd-kit/sortable"
import { makeAutoObservable } from "mobx"

import { PAGE_PHOTOS_COUNT } from "@app/features/book-editor-v2/constants"
import { Page } from "@app/features/book-editor-v2/store/page"
import { Photo } from "@app/features/book-editor-v2/store/photo"

/**
 * Класс описывающий конфигурациюю фотокниги
 */
export class MiniBookProductConfiguration implements IProductConfiguration {
  cover: ICover
  pages: IPage[]
  version: number

  /**
   * Создать новую конфигурацию фотокниги
   * @param {pages} массив страниц
   * @param {cover} параметры для обложки
   */
  constructor(caption: ICaption, pages: IPage[], cover: ICover, version: number, clientAlbum: string) {

    const value = caption?.value
      ? caption.value
      : caption.text instanceof Array
        ? caption.text.length > 1
          ? caption.text.join(" ")
          : caption.text[0]
        : caption.text

    this.version = version
 
    this.cover = {
      photo: new Photo(cover.photo.id, cover.photo.offset, cover.photo?.type, clientAlbum),
      caption: new Caption(value),
    }

    this.pages = pages.map((page) => new Page(page.photos, page.style, clientAlbum))

    makeAutoObservable(this)
  }

  /**
   * Список страниц в которых фото были удалены
   * @returns массив с индексами страниц, начиная с 0
   */
  get deletedPhotosPages(): number[] {
    let invalidPages: number[] = []
    this.pages.map((page: IPage, index) => {
      if (page.photos.findIndex((photo: IPhoto) => photo.id === null) > -1) {
        invalidPages.push(index)
      }
    })

    return invalidPages
  }

  /**
   * Список фотографий, качество которых не соответсвует печать
   * @returns массив строк, где строки являются идентификаторами фотографий
   */
  get lowQualityPhotos(): string[] {
    let lowQualityPhotos: string[] = []
    this.pages.map((page: IPage, index) => {
      const lowQualityPhotosOnPage: IPhoto[] = page.photos.filter((photo) => {
        if (photo.id === null) return false
        return photo.quality === Quality.low
      })
      const lowQualityPhotosIds = lowQualityPhotosOnPage.map((photo: IPhoto) => photo.id)

      lowQualityPhotos.push(...lowQualityPhotosIds)
    })

    return lowQualityPhotos
  }

  /**
   * Заменить фотографии местами, в случае если заменяеимого фото нет, удаляет заменяемое фото
   */
  public movePhoto(from: TPhotoDraggableData, to: TPhotoDraggableData) {
    const movablePhoto = this.pages[from.pageIndex].photos[from.photoIndex]
    const replacablePhoto = this.pages[to.pageIndex].photos[to.photoIndex]

    const movablePhotoId = movablePhoto.id
    const movablePhotoType = movablePhoto?.type
    Boolean(replacablePhoto.id)
      ? movablePhoto.replace(replacablePhoto.id, replacablePhoto?.type)
      : movablePhoto.delete()
    replacablePhoto.replace(movablePhotoId, movablePhotoType)
  }

  /**
   * Переместить разворот
   */
  public moveSpread(from: number, to: number) {
    const leftPageFromIndex = from * 2
    const leftPageToIndex = to * 2

    const rightPageFromIndex = from * 2 + 1
    const rightPageToIndex = to * 2 + 1

    let pages = this.pages

    if (from > to) {
      pages = arrayMove(pages, leftPageFromIndex, leftPageToIndex)
      pages = arrayMove(pages, rightPageFromIndex, rightPageToIndex)
    } else {
      pages = arrayMove(pages, rightPageFromIndex, rightPageToIndex)
      pages = arrayMove(pages, leftPageFromIndex, leftPageToIndex)
    }

    this.pages = pages
    pages = null
  }

  get spreads() {
    return new Array(this.pages.length / 2).fill(0).map((_, index) => {
      return [this.pages[index * 2], this.pages[index * 2 + 1]]
    })
  }

  /**
   * Пустые развороты
   */
  get emptySpreads() {
    const _spreads = this.spreads

    return _spreads.reduce<number[]>((acc, spread, index) => {
      const emptyPagesOnSpread = spread.filter((page) => {
        const photosQuantity = PAGE_PHOTOS_COUNT[page.style]

        const emptyPhotos = page.photos.filter((photo) => !photo.id)

        return emptyPhotos.length === photosQuantity
      })

      if (emptyPagesOnSpread.length === 2) {
        return [...acc, index]
      }

      return acc
    }, [])
  }

  /**
   * Удалить пустые развороты
   */
  deleteEmptySpreads(deletableCount: number) {
    [...this.emptySpreads].reverse().forEach((spreadIndex, index) => {
      index < deletableCount && this.deleteSpread(spreadIndex)
    })
  }

  get spreadsIdList() {
    return this.spreads.map((spread) => [spread[0].id, spread[1].id].join("-"))
  }

  /**
   * Удалить все фото в конфигурации
   */
  public deleteAllPhotos() {
    this.pages.forEach((page) => {
      page.photos.forEach((photo) => {
        photo.delete()
      })
    })
  }

  /**
   * Заменить несколько фотографий начиная с первой
   * @param photoIds
   */
  public replaceBulkPhotos(photoIds: string[]) {
    this.pages.forEach((page) => {
      page.photos.forEach((photo) => {
        if (photoIds.length) {
          const photoId = photoIds.shift()
          photo.replace(photoId)
        } else {
          photo.delete()
        }
      })
    })
  }

  /**
   * Добавть разворот
   * @param spreadIndex индекс разворота после которого необходимо добавить разворот
   */
  public addSpread = (spreadIndex: number) => {
    const pageIndex = (spreadIndex + 1) * 2

    this.pages = [
      ...this.pages.slice(0, pageIndex),
      ...[new Page([new Photo(null, 0)], PageStyle.contain), new Page([new Photo(null, 0)], PageStyle.contain)],
      ...this.pages.slice(pageIndex),
    ]
  }

  /**
   * Удалить разворот
   * @param spreadIndex индекс разворота который необходимо удалить
   */
  public deleteSpread = (spreadIndex: number) => {
    const pageIndex = spreadIndex * 2
    this.pages.splice(pageIndex, 2)
  }

  /**
   * Получить информацию о текущем шаблоне
   */
  get template() {
    return this.pages.map((page) => page.style)
  }
}
