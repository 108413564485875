import { IClientAlbumPhoto, IOffset, IPhoto, IPhotoImage, Quality, Rotation } from "@book-editor-v2/@types"
import { Offset, PhotoImage } from "@book-editor-v2/store"
import { makeAutoObservable, runInAction } from "mobx"

import { clientAlbumStore } from "@app/features/client-upload/store"

import GalleryPhotos from "./gallery-photos"

/** Класс описывающий фотографию */
export class Photo implements IPhoto {
  id: string | null
  type?: string
  clientAlbum?: string
  offset: IOffset
  image: IPhotoImage | null
  quality: Quality | null

  /**
   * Создать фотографию
   * @param photo_id Уникальный идентификатор фотографии
   * @param offset Сдвиг фотографии
   * @param type Тип фотографии (из галереи или клиентское)
   * @param clientAlbum Уникальный идентификатор альбома клиента
   * @param clientPhoto Обьект клиентского фото
   */
  constructor(photo_id: string, offset, type?: string, clientAlbum?: string, clientPhoto?: IClientAlbumPhoto) {
    this.id = null

    this.image = null

    this.quality = null

    this.type = type || null
    this.offset = new Offset(offset.x, offset.y)
    this.clientAlbum = clientAlbum
    const photo = GalleryPhotos.getById(photo_id)

    if (photo_id && type === "client_photo") {
      this.initializeWithClientAlbum(photo_id, clientPhoto)
    } else {
      this.id = photo?.id || null
      this.image = new PhotoImage(photo)
    }

    makeAutoObservable(this, undefined, { deep: true })
  }

  /**
   * Инициализировать фото из клиентского альбома
   * @param photo_id Уникальный идентификатор фотографии
   * @param clientPhoto Объект клиентского фото
   */
  async initializeWithClientAlbum(photo_id: string, clientPhoto) {
    const photo = clientPhoto || (await clientAlbumStore.getPhotoById(photo_id))

    runInAction(() => {
      this.id = photo?.id || null
      this.image = photo ? new PhotoImage(photo) : null
    })

    await this.initializeClientAlbum(this.clientAlbum)
  }

  /**
   * Инициализировать клиентский альбом
   * @param albumId Уникальный идентификатор альбома клиента
   */
  async initializeClientAlbum(albumId: string) {
    if (!clientAlbumStore.clientAlbumData && !clientAlbumStore.isLoadingClientAlbum) {
      if (!clientAlbumStore.loadingPromise) {
        clientAlbumStore.loadingPromise = clientAlbumStore.fetchClientAlbum(albumId).finally(() => {
          clientAlbumStore.loadingPromise = null
        })
      }
      return clientAlbumStore.loadingPromise
    }
  }

  setQuality(quality: Quality) {
    this.quality = quality
  }

  /**
   * Допустимое направление сдвига
   * @returns {Rotation}
   */
  get moveDirection() {
    if (!this.image) {
      return null
    }
    const isHorizontalPhoto = this.image.width > this.image.height
    if (isHorizontalPhoto) return Rotation.horizontal
    return Rotation.vertical
  }

  /**
   * Соотношение сторон фотографии (горизонтальное или вертикальное)
   * @returns {Rotation}
   */
  get rotation() {
    if (!this.image) {
      return null
    }
    const isHorizontalPhoto = this.image.width > this.image.height
    if (isHorizontalPhoto) return Rotation.horizontal

    return Rotation.vertical
  }

  /**
   * Удалить фотографию
   */
  public delete = () => {
    this.id = null
    this.image = null
  }

  /**
   * Заменить фотографию
   * @param photo_id уникальный идентификатор фотографии
   * @param type тип фотографии (из галереи или клиентская)
   */
  public replace = (photo_id: string, type: string) => {
    let photo = GalleryPhotos.getById(photo_id) || clientAlbumStore.getStorePhotoById(photo_id)
    this.id = photo_id
    this.type = type
    this.offset = new Offset(0, 0)

    if (photo) {
      this.image = new PhotoImage(photo)
      this.image.load()
    }
  }

  /**
   * Установить изображение для страницы из готовый ссылки
   * - Параметр photo_id будет установлен как null
   * @param src
   */
  public setImage = (src: string) => {
    this.id = null
    this.image = new PhotoImage(null)
    this.image.setFromSrc(src)
  }

  get ratio() {
    const { width, height } = this.image
    return width / height
  }

  public rotationByRatio(ratio: number): Rotation {
    if (ratio > this.ratio) return Rotation.vertical
    return Rotation.horizontal
  }

  get sizePercents() {
    const { width, height } = this.image

    if (this.rotation === Rotation.vertical) {
      return {
        width: (width / height) * 100 + "%",
        height: "100%",
      }
    }

    return {
      width: "100%",
      height: (height / width) * 100 + "%",
    }
  }
}
