
import { IImageService } from "../@types"

import { GoogleImageURL } from "./google-image-url"
export class ImageService implements IImageService {
  connector: any

  constructor(_connector) {
    this.connector = new _connector()
  }

  // TODO-me: Подумать над флагами, поддержкой webp
  async get(size: number, photo_path: string): Promise<string> {
    const NEXT_PUBLIC_URL_IMAGE_SOURCE = process.env.NEXT_PUBLIC_URL_IMAGE_SOURCE

    const { data } = await this.connector.get(NEXT_PUBLIC_URL_IMAGE_SOURCE, {
      params: { size, photo_path },
    })

    return new GoogleImageURL(data, size).jpg.withoutProtocol.toString()
  }
}
