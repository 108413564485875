class GoogleImageURL {
  constructor(
    private url: string,
    private size: number,
    flags: string[] = []
  ) {
    if (flags.length > 0) {
      this.url = `${this.url}=${flags.join("-")}`
    }
  }

  get withoutProtocol(): GoogleImageURL {
    const regexp = new RegExp("https?://", "igm")
    const src = this.url.replace(regexp, "//")

    return new GoogleImageURL(src, this.size)
  }

  get jpg(): GoogleImageURL {
    return new GoogleImageURL(this.url, this.size, [`s${this.size}`, "rj"])
  }

  toString() {
    return this.url
  }
}

export { GoogleImageURL }
