import { getSpreadPrice, getSpreadPriceWithoutDiscount } from "./get-spread-price"

import { BOOK_PRODUCT_DYNAMIC_SPREADS, MINI_BOOK_PRODUCT_DYNAMIC_SPREADS } from "@app/features/product/constants"

/**
 * Посчитать итоговую цену товара с учетом динамических разворотов
 * Применимо к любому типу товара
 * @param product DTO Продукта
 * @param productConfiguration Конфигурация продукта
 * @returns итоговая цена товара
 */
const getTotalProductPrice = (product, productConfiguration) => {
  const shouldSpreadCalculate = [BOOK_PRODUCT_DYNAMIC_SPREADS, MINI_BOOK_PRODUCT_DYNAMIC_SPREADS].includes(
    product.handle
  )
  const productPrice = product.price.discount?.price || product.price.price

  if (!shouldSpreadCalculate) {
    return parseFloat(productPrice)
  }

  const { price: spreadPrice } = getSpreadPrice(product)

  const totalSpreadsPrice =
    Math.max(parseFloat(productConfiguration.pages.length) - 20, 0) * (parseFloat(spreadPrice.price) / 2)

  return parseFloat(productPrice) + totalSpreadsPrice
}

/**
 * Посчитать итоговую цену товара с учетом динамических разворотов и без учета скидки
 * Применимо к любому типу товара
 * @param product DTO Продукта
 * @param productConfiguration Конфигурация продукта
 * @returns итоговая цена товара
 */
const getTotalProductPriceWithoutDiscount = (product, productConfiguration) => {
  const shouldSpreadCalculate = [BOOK_PRODUCT_DYNAMIC_SPREADS, MINI_BOOK_PRODUCT_DYNAMIC_SPREADS].includes(
    product.handle
  )
  const productPrice = product.price.price

  if (!shouldSpreadCalculate) {
    return parseFloat(productPrice)
  }

  const { price: spreadPrice } = getSpreadPriceWithoutDiscount(product)

  const totalSpreadsPrice =
    Math.max(parseFloat(productConfiguration.pages.length) - 20, 0) * (parseFloat(spreadPrice.price) / 2)

  return parseFloat(productPrice) + totalSpreadsPrice
}

/**
 * Посчитать итоговую цену товара с учетом кол-ва страниц
 * Применимо только к фотокниге и минибуку
 * @param product DTO продукта
 * @param pages Кол-во страниц
 * @returns итоговая цена товара
 */
const getTotalProductPriceFromPages = (product, pages) => {
  const { price: spreadPrice } = getSpreadPrice(product)
  const productPrice = product.price.discount?.price || product.price.price

  const totalSpreadsPrice = Math.max(parseFloat(pages) - 20, 0) * (parseFloat(spreadPrice.price) / 2)

  return parseFloat(productPrice) + totalSpreadsPrice
}

/**
 * Получить итоговую цену товара с учетом кол-ва страниц
 * Применимо только к фотокниге и минибуку
 * @param product DTO продукта
 * @param pages кол-во страниц
 * @returns объект итоговой цены товара
 */
const getTotalProductPriceWithoutDiscountFromPages = (product, pages) => {
  const { price: spreadPrice } = getSpreadPriceWithoutDiscount(product)
  const productPrice = product.price.price

  const totalSpreadsPrice = Math.max(parseFloat(pages) - 20, 0) * (parseFloat(spreadPrice.price) / 2)

  return parseFloat(productPrice) + totalSpreadsPrice
}

export {
  getTotalProductPrice,
  getTotalProductPriceFromPages,
  getTotalProductPriceWithoutDiscount,
  getTotalProductPriceWithoutDiscountFromPages,
}
