import { BookEditor } from "@book-editor-v2/store"
import { IBookEditor } from "@book-editor-v2/@types"
import { MiniBookEditor } from "@book-editor-v2/products/mini-book/store/mini-book-editor"
import { BOOK_PRODUCT_DYNAMIC_SPREADS, MINI_BOOK_PRODUCT_DYNAMIC_SPREADS } from "@app/features/product/constants"

const getProductHandleByEditorInstace = (editorInstace: IBookEditor): string | null => {
  const pages = editorInstace.productOptionValues.pages
  if (editorInstace instanceof BookEditor) return BOOK_PRODUCT_DYNAMIC_SPREADS
  if (editorInstace instanceof MiniBookEditor) return MINI_BOOK_PRODUCT_DYNAMIC_SPREADS
  return null
}

export { getProductHandleByEditorInstace }
