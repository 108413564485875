import { makeAutoObservable } from "mobx"
import { TPhotoSource } from "@book-editor-v2/@types"
import { ALL_PHOTOS } from "@app/features/book-editor-v2/constants"

class PhotoSelectionModalState {
  public photoSource: TPhotoSource

  constructor(source: TPhotoSource) {
    this.photoSource = source
    makeAutoObservable(this)
  }

  setPhotoSource(source: TPhotoSource) {
    this.photoSource = source
  }
}

export const photoSelectionModalState = new PhotoSelectionModalState(ALL_PHOTOS)
