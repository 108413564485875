import { ICaption, ICaptionFont } from "@book-editor-v2/@types"
import { getCanvasFont,getTextWidth } from "@book-editor-v2/products/mini-book/utils"
import { makeAutoObservable } from "mobx"

export class Caption implements ICaption {
  value: string
  lines: number
  words: string[]
  wordsWidth: number[]
  text: string[]

  constructor(value: string) {
    
    // не забываем очистить от emoji
    // OLD -> /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
    this.value = value.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    )
    this.lines = 0
    this.words = []
    this.wordsWidth = []
    this.text = [""]

    makeAutoObservable(this)
  }

  get font(): ICaptionFont {
    return { color: "111111", size: this.captionFontSize(this.value) }
  }

  private captionFontSize(caption: string) {
    if (caption.length <= 20) return 240

    return 190
  }

  public setValue(value: string) {
    this.value = value
  }

  public setCaptionLines(lines: number) {
    this.lines = lines
  }

  public setWords(words: string[]) {
    this.words = words
  }

  public setWordsWidth(words: number[]) {
    this.wordsWidth = words
  }

  // записываем количество строк
  public setLines(refCaption, refPlaceholder) {
    const heightCaption = refCaption.current.offsetHeight // caption real
    const heightPlaceholder = refPlaceholder.current.clientHeight // one line (placeholder)

    this.setCaptionLines(Math.round(heightCaption / heightPlaceholder))
  }

  public setWordsArray() {
    const str = this.value
    const tagTypeBreak = str.includes("<div>") ? "<div>" : "<br>"
    const caption = str
      .replaceAll(tagTypeBreak, "[nls]") // new line start
      .replaceAll("<br>", "")
      .replaceAll("</br>", "")
      .replaceAll("<br />", "")
      .replaceAll("</div>", "")
      .replaceAll("</div", "")
      .replaceAll("</di", "")
      .replaceAll("</d", "")
      .toUpperCase()

    const captionArray = caption.split("[NLS]")
    const resultCaptionArray = captionArray.filter((item) => item !== "")
    if (resultCaptionArray.length > 2) resultCaptionArray.pop()

    return resultCaptionArray
  }

  // записываем слова и их ширины
  public setWordsFunc(canvas, refCaption) {
    const str = this.value
    const arrayCaption = str
      .replaceAll("<div>", " ")
      .replaceAll("<br />", " ")
      .replaceAll("<br/>", " ")
      .replaceAll("<br>", " ")
      .replaceAll("</div>", "")
      .replaceAll("</div", "")
      .replaceAll("</di", "")
      .replaceAll("</d", "")
      .split(/[\s]+/) // чистим и разделяем по пробелам, оставляем </div> для понимания переноса

    const cleanArrayWords = arrayCaption.filter((word) => word !== "")
    cleanArrayWords.push(" ") // найти ширину пробела и вынести ее

    const widthWord = cleanArrayWords.map((word) => getTextWidth(canvas, word, getCanvasFont(refCaption.current)))

    this.setWords(cleanArrayWords)
    this.setWordsWidth(widthWord)
  }

  private detectTextPositionOnCaption(refCaption) {
    const captionLinesCount = this.setWordsArray().length
    const captionWidth = refCaption.current.offsetWidth
    const captionWords = this.words
    const wordsLength = captionWords.length
    const wordsWidth = this.wordsWidth
    const spaceWidth = this.wordsWidth.pop()

    let widthLine = 0
    let indexArrayFirstLine = []
    let indexArrayLastLine = []

    // если не было переноса (Enter)
    if (captionLinesCount === 1) {
      for (let i = 0; i < wordsLength - 1; i++) {
        // если две строки
        if (this.lines > 1) {
          // суммируем длину слов (плюс пробел, он у нас добавлен в конце массива)
          widthLine += wordsWidth[i] + spaceWidth

          // и проверяем, влазит ли слово/слова
          if (widthLine < captionWidth) {
            // добавляем индекс слова в массив первой строки
            indexArrayFirstLine.push(i)
          } else {
            // иначе в массив второй строки
            indexArrayLastLine.push(i)
          }
        } else {
          // иначе слово/слова в первой строке
          // добавляем индекс слова в массив первой строки
          indexArrayFirstLine.push(i)
        }
      }
    }

    // возвращаем массивы, в которых содержатся индексы слов
    return { indexArrayFirstLine, indexArrayLastLine }
  }

  public buildArrayText(refCaption) {
    const captionLinesCount = this.setWordsArray().length
    const actualWords = this.words.map((word) =>
      word.replaceAll("</div>", "").replaceAll("</div", "").replaceAll("</di", "").replaceAll("</d", "")
    )
    const { indexArrayFirstLine, indexArrayLastLine } = this.detectTextPositionOnCaption(refCaption)
    const firstLineText = indexArrayFirstLine.length ? indexArrayFirstLine.map((number) => actualWords[number]) : []
    const lastLineText = indexArrayLastLine.length ? indexArrayLastLine.map((number) => actualWords[number]) : []
    const defaultText = this.value
      .replaceAll("<div>", "")
      .replaceAll("</div>", "")
      .replaceAll("<br />", "")
      .replaceAll("<br/>", "")
      .replaceAll("<br>", "")
      .toUpperCase()

    // если был перенос (Enter)
    if (captionLinesCount > 1) {
      // просто передаем уже сформированный массив
      this.text = this.setWordsArray()
    } else {
      // иначе работаем с индексами
      if (firstLineText.length && lastLineText.length) {
        this.text = [firstLineText.join(" ").toUpperCase(), lastLineText.join(" ").toUpperCase()]
      } else {
        if (firstLineText.length) {
          this.text = [firstLineText.join(" ").toUpperCase()]
        } else {
          if (lastLineText.length) {
            this.text = [" ", lastLineText.join(" ").toUpperCase()]
          } else {
            this.text = [defaultText]
          }
        }
      }
    }

    // Пока не будет прибегать к этому
    // if (this.text[0] === "") {
    //   this.text = [defaultText]
    // }
  }
}
