import { api } from "@app/api"
import { REQUEST_TYPE } from "@app/api/constants"

const createClientAlbum = async () => {
  try {
    const { data: response } = await api().post("/client-albums")

    return response.data
  } catch (err) {
    console.log(err)
  }
}

const getClientAlbum = async (albumId: string | Promise<string>) => {
  try {
    const { data: response } = await api().get(`/client-albums/${albumId}`)

    return response.data
  } catch (error) {
    console.error(error.response)

    return null
  }
}

const createClientAlbumPhoto = async () => {
  try {
    const { data: response } = await api().post("/client-photos")

    return response.data
  } catch (err) {
    console.log(err)
  }
}

const removeClientAlbumPhotos = async (photosId: Array<string>) => {
  try {
    const { data: response } = await api().delete("/client-photos", {
      data: {
        ids: photosId,
      },
    })

    return response.data
  } catch (err) {
    console.log(err)
  }
}

const getClientAlbumPhoto = async (photoId: string) => {
  try {
    const { data: response } = await api().get(`/client-photos/${photoId}`)

    return response.data
  } catch (error) {
    console.error(error.response)

    return null
  }
}

const getClientAlbumPhotoServer = async (photoId: string, requestType: string = REQUEST_TYPE.SERVER) => {
  try {
    const { data: response } = await api(requestType as any).get(`/client-photos/${photoId}`)

    return response.data
  } catch (err) {
    console.log(err)
  }
}

export {
  createClientAlbum,
  getClientAlbum,
  createClientAlbumPhoto,
  getClientAlbumPhoto,
  getClientAlbumPhotoServer,
  removeClientAlbumPhotos,
}
