import { IPhotoImage } from "@book-editor-v2/@types"
import { HttpService, ImageService } from "@book-editor-v2/services"
import { makeAutoObservable, runInAction } from "mobx"

/** Класс описывающий изображение */
export class PhotoImage implements IPhotoImage {
  galleryPhoto: any
  src: string
  width: number
  height: number
  isLoading: boolean

  /**
   * Создать новое изображение
   * @param {photo_id} уникальный идентификатор фотографии
   */
  constructor(galleryPhoto: any, private imageService = new ImageService(HttpService)) {
    this.galleryPhoto = galleryPhoto
    this.src = null
    this.width = null
    this.height = null
    this.isLoading = true

    makeAutoObservable(this, undefined, { deep: true })
  }

  /**
   * Создать виртуальный объект DOMImage
   * @param {src} ссылка на изображение будет установлена как атрибут src
   * @returns DOMImageElement
   */
  async createImageElement(src: string): Promise<HTMLImageElement> {
    const image = await new Promise<HTMLImageElement>((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = src
    })

    return image
  }

  /**
   * Установить состояние загрузки фото
   */
  set loading(loadingState: boolean) {
    this.isLoading = loadingState
  }

  /**
   * Установить изображение
   * @param src
   * @param width
   * @param height
   */
  private set(src: string, width: number, height: number) {
    this.src = src
    this.width = width
    this.height = height
    this.isLoading = false
  }

  /**
   * Устагновить изображение из src
   * @param src
   */
  public async setFromSrc(src: string) {
    this.isLoading = true
    const { naturalWidth: width, naturalHeight: height } = await this.createImageElement(src)

    runInAction(() => {
      this.set(src, width, height)
      this.isLoading = false
    })
  }

  /**
   * Загрузить изображение
   */
  public async load() {
    try {
      this.loading = true
      let src = await this.imageService.get(
        2020,
        this.galleryPhoto?.photos ? this.galleryPhoto.photos[0] : this.galleryPhoto.fileKey
      )

      this.src = src
      this.width = this.galleryPhoto.width
      this.height = this.galleryPhoto.height
      this.loading = false

      // hasPhotoSize ? this.set(src, photo.width, photo.height) : this.setFromSrc(src)
    } catch (error) {
      console.log("Ошибка во время загрузки фотографии с идентификатором: " + this.galleryPhoto?.id, error)
      throw new Error("Ошибка во время загрузки фотографии с идентификатором. Подробнее в консоли.")
    }

    // const hasPhotoSize = typeof photo?.width === "number" && typeof photo?.height === "number";

    this.loading = false
  }
}
