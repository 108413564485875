import { MutableRefObject, ReactElement } from "react"
import { Coordinates, ImageSize } from "react-advanced-cropper"

import { ALL_PHOTOS, FAVORITES_PHOTOS } from "@app/features/book-editor-v2/constants"

export type TPhotoSource = typeof ALL_PHOTOS | typeof FAVORITES_PHOTOS | string

export type BookPagesCount = 20 | 40 | 60

export type TPhotoDraggableData = {
  pageIndex: number
  photoIndex: number
}

export type TSpreadDraggableData = {
  id: string
  spreadIndex: number
}

export type TEditorDraggableData = TPhotoDraggableData | TSpreadDraggableData

export enum Rotation {
  horizontal = "horizontal",
  vertical = "vertical",
}

export enum BookCoverColor {
  gray = "gray",
  beige = "beige",
  brown = "brown",
}

export type TCoverSwitchOption = {
  value: string
  color: string
}

export enum Quality {
  low = "low",
  normal = "mormal",
}

export enum PageStyle {
  fill = "fill", // Одна фото на всю страницу
  contain = "contain", // Одна фото на странице с заполнением пропорций
  four_in_one = "four_in_one", // Четыре квадратные фото на странице
  two_in_one_vertical = "two_in_one_vertical", // Две вертикальные фото на странице
  two_in_one_horizontal = "two_in_one_horizontal", // Две горизонатльные фото на странице
}

export type TSizesDouble = {
  width?: number
  height?: number
  min?: number
  max?: number
}

export interface IProductConfigurationMapper {
  backEndConfiguration: Object
  clientConfiguration: Object
}

export type TSideQualitySizes = {
  low: {
    minSide: number
    maxSide: number
  }
}

export interface ISizes {
  low?: number
  double?: TSizesDouble
}

export type TSizesType = {
  [propName: string]: ISizes
}

export interface IOffset {
  x: number
  y: number

  set: (x: number, y: number) => void
  convertToPercents: (coordinates: Coordinates, imageSize: ImageSize) => { x: number; y: number }
  convertToPixels: (
    coordinates: Coordinates,
    imageSize: ImageSize,
    percents: { x: number; y: number }
  ) => { x: number; y: number }
}

export interface IPhotoImage {
  src: string
  width: number
  height: number
  isLoading: boolean

  load: () => void
  setFromSrc: (src: string) => void
}

export type PercentsSize = {
  width: string
  height: string
}

export interface IPhoto {
  id: string | null
  offset: IOffset
  image: IPhotoImage
  quality: Quality | null
  ratio: number
  type?: string

  rotation: Rotation
  sizePercents: PercentsSize
  replace: (photo_id: string, type?: string) => void
  setImage: (src: string) => void
  setQuality: (quality: Quality) => void
  rotationByRatio: (ratio: number) => Rotation
  delete: () => void
}

export interface IPage {
  readonly id: string
  style: PageStyle
  photos: IPhoto[]
  photoContainerRatio: number

  setType: (type: PageStyle) => void
}

export interface IProductConfiguration {
  version: number
  cover: ICover
  pages: IPage[]
  template: PageStyle[]

  spreadsIdList: string[]
  lowQualityPhotos: string[]
  emptySpreads: number[]
  deleteEmptySpreads: (deletableCount: number) => void
  deletedPhotosPages: number[]
  movePhoto: (from: TPhotoDraggableData, to: TPhotoDraggableData) => void
  moveSpread: (from: number, to: number) => void
  deleteAllPhotos: () => void
  replaceBulkPhotos: (photos: string[]) => void
  addSpread: (appendIndex: number) => void
  deleteSpread: (index: number) => void
}

export interface ICanvasConfiguration {
  photo: IPhoto
}

export interface IProductOptions {
  color: BookCoverColor
  pages: BookPagesCount
  setColor: (color: BookCoverColor) => void
}

export interface IEditor {
  JSON: string
  isReady: boolean
}

export interface IBookEditor extends IEditor {
  saved: boolean
  hasUnsavedChanges: boolean
  clientAlbum?: IClientAlbumId
  productOptionValues: IProductOptions
  productConfiguration: IProductConfiguration
  isCleanConfiguration?: boolean

  setUnsavedChanges: (value: boolean) => void
  setCleanConfiguration: (value: boolean) => void
}

export interface ICanvasEditor extends IEditor {
  saved: boolean
  hasUnsavedChanges: boolean
  clientAlbum?: IClientAlbumId
  productOptionValues: {}
  productConfiguration: ICanvasConfiguration

  setUnsavedChanges: (value: boolean) => void
}

export interface IGetQualityArguments {
  width: number
  height: number
  rotation: Rotation
}

export interface IQualityService {
  sizes: ISizes | TSideQualitySizes

  getQuality: (args: IGetQualityArguments) => Quality
}

export interface ILocalStorageService {
  localStorageKey: string

  load: () => string
  hasSaved: () => boolean
  save: (json: string) => void
}

export interface IImageService {
  connector: any
  get: (size: number, photo_path: string, flags: string[]) => Promise<string>
}

export interface IGalleryPhoto {
  id: string
  type?: string
  width: number
  height: number
  photos: Array<string>
}

export type TPhotoResource = Array<IGalleryPhoto>

export interface IGalleryScene {
  id: string
  name: string
  photos: TPhotoResource
}

export type TGalleryScenes = Array<IGalleryScene>

export interface IClientAlbum {
  id: string
  photoSize: number
  photoCount: number
  photos: IClientAlbumPhoto[]
}

export type TClientAlbum = Array<IClientAlbum>

export interface IClientAlbumId {
  id: string
}

export interface IClientAlbumPhoto {
  id: string
  name: string
  type?: string
  contentType: string
  fileKey: string
  width: number
  height: number
}

export interface IClientAlbumPhotos {
  album: {
    id: string
  }
  id: string
  name: string
  type?: string
  contentType: string
  fileKey: string
  width: number
  height: number
}

export type TClientAlbumPhoto = Array<IClientAlbumPhoto>

export interface IGeneratorService {
  generate: (cover: any, color: string, length: number, productName: string, resources: Array<TPhotoResource>) => string
}

export type TSettingsGeneratorService = {
  empty?: boolean
}

export type PhotoContainerRatio = {
  fill: number
  contain: number
  four_in_one: number
  two_in_one_vertical: number
  two_in_one_horizontal: number
}

export type PagePhotosCount = {
  fill: number
  contain: number
  four_in_one: number
  two_in_one_vertical: number
  two_in_one_horizontal: number
}

export interface ImagePreviewFillProps {
  image: IPhotoImage
  offset: IOffset
  rotation: Rotation
  className?: string
  onClick?: () => void
}

export type ExtendTemplateGenerationLength = {
  20: number
  40: number
  60: number
}

export interface ICaptionFont {
  color: string
  size: number
}

export interface ICaption {
  text: string[]
  font: ICaptionFont
  value: string
  lines: number
  words: string[]
  wordsWidth: number[]
  setValue: (value: string) => void
  setCaptionLines: (lines: number) => void
  setWords: (words: string[]) => void
  setWordsWidth: (words: number[]) => void

  setLines: (refCaption: MutableRefObject<HTMLDivElement>, refPlaceholder: MutableRefObject<HTMLDivElement>) => void
  setWordsFunc: (canvas: HTMLCanvasElement, refCaption: MutableRefObject<HTMLDivElement>) => void
  buildArrayText: (refCaption: MutableRefObject<HTMLDivElement>) => void
}

export interface ICover {
  photo: IPhoto
  caption?: ICaption
}

export type TPageActualStyles = Array<string>

export interface IPageStyleOptions {
  children: ReactElement
  value: string
  tooltip?: string
}

export const enum PhotoAction {
  edit = "edit",
  delete = "delete",
}

export type TPageStyleOptions = Array<IPageStyleOptions>

export type ProductShortName = "book" | "book-customer" | "canvas" | "mini-book" | "photo-set"
