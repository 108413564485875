import { BookCoverColor, BookPagesCount, IProductOptions } from "@book-editor-v2/@types"
import { makeAutoObservable } from "mobx"

export class ProductOptions implements IProductOptions {
  color: BookCoverColor
  pages: BookPagesCount

  constructor(color: BookCoverColor, pages: BookPagesCount) {
    this.color = color
    this.pages = pages
    makeAutoObservable(this)
  }

  public setColor(color: BookCoverColor) {
    this.color = color
  }
}
