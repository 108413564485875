import { PrivateGalleryInterface, PublicGalleryInterface } from "@app/features/gallery/types"
import { createInitialPhotoBook } from "@app/features/product/utils"

const getBookEditorPhotosFromGallery = (gallery, selectionCount) => {
  let photos = gallery.scenes
    .map((scene) => scene.mediaFiles)
    .flat()
    .filter((file) => file.type === "photo")
  return photos.length < selectionCount ? null : photos
}

const createInitialPhotoBookCover = (gallery, photos) => {
  const photo_id = !gallery?.cover ? null : photos[0].id
  return {
    photo_id,
    offset: {
      x: 0,
      y: 0,
    },
  }
}

const createProductOptionValues = (color: string) => {
  return [
    {
      category: "COVER_COLOR",
      value: color,
    },
  ]
}

const generateInitialPhotoBook = (
  gallery: PublicGalleryInterface | PrivateGalleryInterface,
  selectionCount: number,
  color: string
) => {
  const photos = getBookEditorPhotosFromGallery(gallery, selectionCount)
  if (photos == null) return false

  const pages = createInitialPhotoBook(photos, selectionCount)
  const cover = createInitialPhotoBookCover(gallery.cover, photos)
  const productOptionValues = createProductOptionValues(color)

  return {
    productOptionValues,
    productConfiguration: { cover, pages },
  }
}

export { generateInitialPhotoBook }
