import { makeAutoObservable } from "mobx"

type Status = {
  handle: "ACTIVE"
}

type ProductInfoOption = {
  category: {
    handle: "ADDITIONAL_SPREAD"
  }
  status: Status
  values: Array<{
    value: string
    status: Status
    free: boolean
    price: {
      currency: {
        handle: "USD"
      }
      price: string
      discount: null
    }
  }>
}

// TODO: Dmitriy Khanin - добавить .d.ts codegen для генерации моделей, тут вся информация о продукте
type ProductInfoModel = {
  minPagesQuantity: number
  maxPagesQuantity: number
  options: ProductInfoOption[]
}

const INITIAL_DATA: ProductInfoModel = {
  minPagesQuantity: 0,
  maxPagesQuantity: 0,
  options: [],
}

/** Класс описывающий фотографию */
class ProductInfo {
  private _data = INITIAL_DATA

  constructor() {
    makeAutoObservable(this)
  }

  public setData(data: any) {
    this._data = JSON.parse(JSON.stringify(data))
  }

  get data() {
    return JSON.parse(JSON.stringify(this._data))
  }
}

export const productInfoStore = new ProductInfo()
