import { makeAutoObservable, runInAction } from "mobx"
import { IClientAlbum, IClientAlbumPhoto, IClientAlbumPhotos } from "@book-editor-v2/@types"

import {
  createClientAlbum,
  getClientAlbum,
  getClientAlbumPhoto,
  removeClientAlbumPhotos,
} from "@app/features/client-upload/requests"

import { CLIENT_ALBUM_ID } from "@app/features/client-upload/constants"

/**
 * Класс, описывающий клиентский альбом
 */
class ClientAlbumStore {
  clientAlbumId: string | Promise<string> | null = null
  clientAlbumData: IClientAlbum | null = null
  clientAlbumPhotos: Array<IClientAlbumPhotos> = []
  isLoadingClientAlbum = false
  isUploadComplete = false
  loadingPromise: Promise<void> | null = null

  constructor() {
    makeAutoObservable(this)

    // Привязка методов к контексту класса
    this.setClientAlbumId = this.setClientAlbumId.bind(this)
    this.setClientAlbumData = this.setClientAlbumData.bind(this)
    this.setClientAlbumPhotos = this.setClientAlbumPhotos.bind(this)
    this.setIsLoadingClientAlbum = this.setIsLoadingClientAlbum.bind(this)
    this.setIsUploadComplete = this.setIsUploadComplete.bind(this)
    this.fetchClientAlbum = this.fetchClientAlbum.bind(this)
    this.fetchClientAlbumPhoto = this.fetchClientAlbumPhoto.bind(this)
    this.createAlbum = this.createAlbum.bind(this)
    this.addPhotoToAlbum = this.addPhotoToAlbum.bind(this)
    this.removePhotoFromAlbum = this.removePhotoFromAlbum.bind(this)
    this.removePhoto = this.removePhoto.bind(this)
    this.getPhotoById = this.getPhotoById.bind(this)
    this.getPhotoByIdAndAlbumId = this.getPhotoByIdAndAlbumId.bind(this)
  }

  /**
   * Установить идентификатор клиентского альбома
   * @param id Уникальный идентификатор альбома или Promise, возвращающий идентификатор
   */
  setClientAlbumId(id: string | Promise<string>) {
    this.clientAlbumId = id
  }

  /**
   * Установить данные клиентского альбома
   * @param data Объект с данными клиентского альбома
   */
  setClientAlbumData(data: IClientAlbum) {
    this.clientAlbumData = data
  }

  /**
   * Добавить фотографию в массив фотографий клиентского альбома
   * @param photo Объект с данными фотографии
   */
  setClientAlbumPhotos(photo: IClientAlbumPhotos) {
    if (!this.clientAlbumPhotos) {
      this.clientAlbumPhotos = []
    }

    this.clientAlbumPhotos.push(photo)
  }

  /**
   * Установить состояние загрузки клиентского альбома
   * @param isLoading Булево значение состояния загрузки
   */
  setIsLoadingClientAlbum(isLoading: boolean) {
    this.isLoadingClientAlbum = isLoading
  }

  /**
   * Установить состояние завершения загрузки
   * @param isComplete Булево значение состояния завершения загрузки
   */
  setIsUploadComplete(isComplete: boolean) {
    this.isUploadComplete = isComplete
  }

  /**
   * Загрузить данные клиентского альбома по идентификатору
   * @param id Уникальный идентификатор альбома или Promise, возвращающий идентификатор
   */
  async fetchClientAlbum(id: string | Promise<string>) {
    this.setIsLoadingClientAlbum(true)

    const resolvedId = await Promise.resolve(id)

    if (!resolvedId || resolvedId === "null") {
      this.setIsLoadingClientAlbum(false)
      return
    }

    try {
      const clientAlbum = await getClientAlbum(resolvedId)
      runInAction(() => {
        this.setClientAlbumData(clientAlbum)
        this.setClientAlbumId(resolvedId)
      })
    } catch (error) {
      console.error(`Не удалось загрузить альбом клиента с id ${resolvedId}`, error)
    } finally {
      runInAction(() => {
        this.setIsLoadingClientAlbum(false)
      })
    }
  }

  /**
   * Загрузить данные фотографии из клиентского альбома по идентификатору
   * @param photoId Уникальный идентификатор фотографии
   */
  async fetchClientAlbumPhoto(photoId: string) {
    this.setIsLoadingClientAlbum(true)

    try {
      const clientAlbumPhoto = await getClientAlbumPhoto(photoId)

      runInAction(() => {
        this.setClientAlbumPhotos(clientAlbumPhoto)
        this.setClientAlbumId(clientAlbumPhoto.album.id)
      })
    } catch (error) {
      console.error(`Не удалось загрузить фото клиента с id ${photoId}`, error)
    } finally {
      runInAction(() => {
        this.setIsLoadingClientAlbum(false)
      })
    }
  }

  /**
   * Создать новый клиентский альбом
   */
  async createAlbum() {
    this.setIsLoadingClientAlbum(true)
    try {
      const clientAlbum = await createClientAlbum()
      if (clientAlbum?.id) {
        localStorage.setItem(CLIENT_ALBUM_ID, clientAlbum.id)
        runInAction(() => {
          this.setClientAlbumId(clientAlbum.id)
        })
        // await this.fetchClientAlbum(clientAlbumId.id)
      }

      return clientAlbum
    } catch (error) {
      console.error("Не удалось создать альбом клиента", error)
    } finally {
      runInAction(() => {
        this.setIsLoadingClientAlbum(false)
      })
    }
  }

  /**
   * Добавить фотографию в альбом
   * @param photo Объект с данными фотографии
   */
  addPhotoToAlbum(photo: any) {
    if (this.clientAlbumData) {
      runInAction(() => {
        this.clientAlbumData = {
          ...this.clientAlbumData,
          photos: [...this.clientAlbumData.photos, photo],
          photoCount: this.clientAlbumData.photoCount + 1,
        }
      })
    }
  }

  /**
   * Удалить фотографию из альбома по идентификатору
   * @param photoId Уникальный идентификатор фотографии
   */
  removePhotoFromAlbum(photoId: string) {
    if (this.clientAlbumData) {
      runInAction(() => {
        this.clientAlbumData = {
          ...this.clientAlbumData,
          photos: this.clientAlbumData.photos.filter((photo) => photo.id !== photoId),
          photoCount: this.clientAlbumData.photoCount - 1,
        }
      })
    }
  }

  /**
   * Удалить фотографию из альбома
   * @param photoId Уникальный идентификатор фотографии
   */
  async removePhoto(photoId: string) {
    if (!this.clientAlbumId) return

    try {
      await removeClientAlbumPhotos([photoId])
      this.removePhotoFromAlbum(photoId)
    } catch (error) {
      console.error(`Не удалось удалить фото с id ${photoId}`, error)
    }
  }

  /**
   * Получить фотографию по идентификатору
   * @param id Уникальный идентификатор фотографии
   * @returns Объект с данными фотографии или undefined, если фотография не найдена
   */
  async getPhotoById(id: string): Promise<IClientAlbumPhoto | undefined> {
    if (!this.clientAlbumData) {
      await this.fetchClientAlbumPhoto(id)

      return this.clientAlbumPhotos.find((photo) => photo.id === id)
    }

    return this.clientAlbumData?.photos.find((photo) => photo.id === id)
  }

  /**
   * Получить фотографию из текущего альбома по идентификатору
   * @param id Уникальный идентификатор фотографии
   * @returns Объект с данными фотографии или undefined, если фотография не найдена
   */
  getStorePhotoById(id: string): IClientAlbumPhoto | undefined {
    if (!this.clientAlbumData) return

    return this.clientAlbumData?.photos.find((photo) => photo.id === id)
  }

  /**
   * Получить фотографию по идентификатору фотографии и альбома
   * @param photoId Уникальный идентификатор фотографии
   * @param albumId Уникальный идентификатор альбома
   * @returns Объект с данными фотографии или undefined, если фотография не найдена
   */
  async getPhotoByIdAndAlbumId(photoId: string, albumId: string): Promise<IClientAlbumPhoto | undefined> {
    if (!this.clientAlbumData || (typeof albumId !== "undefined" && this.clientAlbumId !== albumId)) {
      await this.fetchClientAlbum(albumId)
    }

    return this.clientAlbumData?.photos.find((photo) => photo.id === photoId)
  }

  /**
   * Инициализировать альбом клиента по идентификатору
   * @param albumId Уникальный идентификатор альбома
   */
  async initClientAlbumById(albumId: string) {
    if (!albumId) return
    await this.fetchClientAlbum(albumId)
  }
}

const clientAlbumStore = new ClientAlbumStore()
export { clientAlbumStore }
