import create from "zustand"

interface WarningState {
  isWarningVisible: boolean
  warningType: string | null
  showWarning: (type: string) => void
  hideWarning: () => void
}

const useWarningStore = create<WarningState>((set) => ({
  isWarningVisible: false,
  warningType: null,
  showWarning: (type: string) => set({ isWarningVisible: true, warningType: type }),
  hideWarning: () => set({ isWarningVisible: false, warningType: null }),
}))

function useWarning() {
  const isWarningVisible = useWarningStore((state) => state.isWarningVisible)
  const warningType = useWarningStore((state) => state.warningType)
  const showWarning = useWarningStore((state) => state.showWarning)
  const hideWarning = useWarningStore((state) => state.hideWarning)

  return { isWarningVisible, showWarning, hideWarning, warningType }
}

export { useWarning }
