import { IGetQualityArguments, IQualityService, Quality, Rotation, TSideQualitySizes } from "@book-editor-v2/@types"

export class SideQualityService implements IQualityService {
  /**
   * Создать сервис для проверки качества фото
   * по нескольким сторонам одновременно
   * @param sizes - Допуски размеров в пикселях
   */
  constructor(public sizes: TSideQualitySizes) {}

  /**
   * Проверить размер по минимальной стороне
   * @param pixels - Размер стороны фото в пикселях
   * @private
   */
  private isLowMinSide(pixels: number): boolean {
    return pixels < this.sizes.low.minSide
  }

  /**
   * Проверить размер по максимальной стороне
   * @param pixels - Размер стороны фото в пикселях
   * @private
   */
  private isLowMaxSide(pixels: number): boolean {
    return pixels < this.sizes.low.maxSide
  }

  /**
   * Получить качество для горизонтального фото
   * @param width - Ширина фото
   * @param height - Высота фото
   * @private
   */
  private horizontalPhotoQuality(width: number, height: number): Quality {
    if (this.isLowMaxSide(width) || this.isLowMinSide(height)) {
      return Quality.low
    }

    return Quality.normal
  }

  /**
   * Получить качество для вертикального фото
   * @param width - Ширина фото в пикселях
   * @param height - Высота фото в пикселях
   * @private
   */
  private verticalPhotoQuality(width: number, height: number): Quality {
    if (this.isLowMaxSide(height) || this.isLowMinSide(width)) {
      return Quality.low
    }

    return Quality.normal
  }

  /**
   * Получить качество фото в соответствии с размерами сторон
   * @param width - Ширина фото в пикселях
   * @param height - Высота фото в пикселях
   * @param rotation - Тип изображения в соответствии с соотношением сторон (horizontal / vertical)
   */
  public getQuality({ width, height, rotation }: IGetQualityArguments): Quality {
    if (rotation === Rotation.horizontal) {
      return this.horizontalPhotoQuality(width, height)
    }

    return this.verticalPhotoQuality(width, height)
  }
}
