import {
  BookCoverColor,
  BookPagesCount,
  IBookEditor,
  IClientAlbumId,
  IProductConfiguration,
  IProductOptions,
  PageStyle,
} from "@book-editor-v2/@types"
import { MiniBookProductConfiguration } from "@book-editor-v2/products/mini-book/store/mini-book-product-configuration"
import { ProductOptions } from "@book-editor-v2/store"
import { makeAutoObservable } from "mobx"

import { CLIENT_ALBUM_ID } from "@app/features/client-upload/constants"

/**
 * Класс описывающий редактор miniBook
 */
export class MiniBookEditor implements IBookEditor {
  saved: boolean
  productOptionValues: IProductOptions
  productConfiguration: IProductConfiguration
  clientAlbum: IClientAlbumId
  public isCleanConfiguration?: boolean

  constructor() {
    this.saved = true
    this.productConfiguration = null
    this.productOptionValues = null
    this.clientAlbum = null
    this.isCleanConfiguration = false

    makeAutoObservable(this, {}, { autoBind: true, deep: true })
  }

  set JSON(json: string) {
    try {
      const { productConfiguration, productOptionValues, clientAlbum } = JSON.parse(json)
      let { pages, cover } = productConfiguration
      const { pages: length, color } = productOptionValues

      pages = pages.map((page) => {
        return {
          style: PageStyle[page.style],
          photos: page.photos,
        }
      })

      const clientAlbumId = clientAlbum?.id || localStorage.getItem(CLIENT_ALBUM_ID)

      this.productOptionValues = new ProductOptions(color as BookCoverColor, length as BookPagesCount)
      this.productConfiguration = new MiniBookProductConfiguration(
        cover.caption,
        pages,
        cover,
        productConfiguration.version,
        clientAlbumId
      )
      this.clientAlbum = { id: clientAlbumId }
    } catch (error) {
      console.log("Ошибка установки конфигурации из JSON: ", error)
    }
  }

  get isReady() {
    return typeof this.productConfiguration?.pages === "object" && typeof this.productConfiguration?.cover === "object"
  }

  /**
   * Получить конфигурацию фотокниги и параметров в формате json строки
   */
  get JSON() {
    return JSON.stringify({
      clientAlbum: this.clientAlbum,
      productOptionValues: this.productOptionValues,
      productConfiguration: {
        version: this.productConfiguration.version,
        cover: {
          photo: {
            id: this.productConfiguration.cover.photo.id,
            offset: this.productConfiguration.cover.photo.offset,
            type: this.productConfiguration.cover.photo?.type,
          },
          caption: {
            text: this.productConfiguration.cover?.caption.text,
            value: this.productConfiguration.cover?.caption?.value,
            font: {
              color: this.productConfiguration.cover?.caption?.font.color,
              size: this.productConfiguration.cover?.caption?.font.size,
            },
          },
        },
        pages: this.productConfiguration.pages.map((page) => {
          return {
            style: page.style,
            photos: page.photos.map((photo) => ({
              id: photo.id,
              offset: photo.offset,
              type: photo?.type,
            })),
          }
        }),
      },
    })
  }

  setUnsavedChanges(value: boolean) {
    this.saved = !value
  }

  get hasUnsavedChanges() {
    return !this.saved
  }

  setCleanConfiguration(value: boolean) {
    this.isCleanConfiguration = value
  }
}
