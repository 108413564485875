import { IPage, IPhoto,PageStyle } from "@book-editor-v2/@types"
import { PAGE_PHOTOS_COUNT, PHOTO_CONTAINER_RATIO } from "@book-editor-v2/constants"
import { QualityService } from "@book-editor-v2/services"
import {Photo } from "@book-editor-v2/store"
import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"

import { CLIENT_ALBUM_ID } from "@app/features/client-upload/constants"

/** Класс представляющий страницу */
class Page implements IPage {
  id: string
  qualityService: QualityService
  photos: Photo[]
  clientAlbum?: string
  style: PageStyle

  /**
   * Создать страницу
   * @param {photos} массив фото для страницы
   * @param {type} тип страницы
   */
  constructor(photos: IPhoto[], style: PageStyle, clientAlbum?: string) {
    this.id = nanoid()
    this.clientAlbum = clientAlbum || localStorage.getItem(CLIENT_ALBUM_ID)
    
    this.photos = photos.map((photo) => {
      return new Photo(photo.id, photo.offset, photo?.type, clientAlbum)
    })
    this.style = style
    makeAutoObservable(this, {}, { autoBind: true })
  }

  private fillPhotos = (length: number): Photo[] => {
    let filledPhotos: Photo[] = []
    let selectedPhotos: Photo[] = this.photos.filter((photo) => photo.id !== null)

    for (var i = 0; i < length; i++) {
      if (typeof selectedPhotos[i] === "undefined") {
        const emptyPhoto = new Photo(null, { x: 0, y: 0 }, "photo", this.clientAlbum)
        filledPhotos.push(emptyPhoto)
      } else {
        const { id, type } = selectedPhotos[i]
        filledPhotos.push(new Photo(id, { x: 0, y: 0 }, type, this.clientAlbum))
      }
    }

    return filledPhotos
  }

  /**
   * Установить новый тип страницы
   * @param {type} тип страницы
   */
  setType(style: PageStyle) {
    if (style === this.style) return

    const length = PAGE_PHOTOS_COUNT[style as string]
    this.style = style
    this.photos = this.fillPhotos(length)
  }

  get photoContainerRatio(): number {
    return PHOTO_CONTAINER_RATIO[this.style as string]
  }
}

export { Page }
