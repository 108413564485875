import { apiRoot } from "@app/api"

export class HttpService {
  async get(path, params): Promise<any> {
    const axiosInstance: any = apiRoot()

    const response = await axiosInstance.get(path, params).catch((error) => {
      console.log("BookEditorHttpService: Network error!", error)
    })

    return response
  }
}
