import create from "zustand"

// Объявление глобальных типов
declare global {
  interface Window {
    isCanceledUpload: boolean
  }
}

interface UploaderBarState {
  isUploaderBarVisible: boolean
  uploadedFiles: number
  uploadedCountFiles: number
  totalUploads: number
  totalCountUploads: number
  stopped: boolean
  isUploadComplete: boolean
  showUploaderBar: () => void
  hideUploaderBar: () => void
  updateTotalUploads: (weight: number) => void
  updateTotalCountUploads: (count: number) => void
  updateUploadedFiles: (weight: number) => void
  updateUploadedCountFiles: (count: number) => void
  resetUploaderBar: () => void
  initializeUploaderBar: () => void
  cancelUpload: () => void
  resetPartUploaderBar: (weight: number, uploadedWeight: number) => void
  resetPartUploaderCountBar: (count: number, uploadedCount: number) => void
  stopPartUpload: (count: number, weight: number, uploadedCount: number, uploadedWeight: number) => void
}

const useUploaderBarStore = create<UploaderBarState>((set, get) => ({
  isUploaderBarVisible: false,
  uploadedFiles: 0,
  uploadedCountFiles: 0,
  totalUploads: 0,
  totalCountUploads: 0,
  stopped: false,
  isUploadComplete: false,

  showUploaderBar: () => set({ isUploaderBarVisible: true }),

  hideUploaderBar: () => set({ isUploaderBarVisible: false }),

  updateTotalUploads: (weight) =>
    set((state) => {
      const totalUploads = state.totalUploads + weight
      return {
        totalUploads,
        isUploadComplete: state.uploadedFiles === totalUploads && state.uploadedCountFiles === state.totalCountUploads,
      }
    }),

  updateTotalCountUploads: (count) =>
    set((state) => {
      const totalCountUploads = state.totalCountUploads + count
      return {
        totalCountUploads,
        isUploadComplete: state.uploadedFiles === state.totalUploads && state.uploadedCountFiles === totalCountUploads,
      }
    }),

  updateUploadedFiles: (weight) =>
    set((state) => {
      const uploadedFiles = state.uploadedFiles + weight
      return {
        uploadedFiles,
        isUploadComplete: uploadedFiles === state.totalUploads && state.uploadedCountFiles === state.totalCountUploads,
      }
    }),

  updateUploadedCountFiles: (count) =>
    set((state) => {
      const uploadedCountFiles = state.uploadedCountFiles + count
      return {
        uploadedCountFiles,
        isUploadComplete: state.uploadedFiles === state.totalUploads && uploadedCountFiles === state.totalCountUploads,
      }
    }),

  resetUploaderBar: () =>
    set({
      isUploaderBarVisible: false,
      totalUploads: 0,
      totalCountUploads: 0,
      uploadedFiles: 0,
      uploadedCountFiles: 0,
      isUploadComplete: false,
    }),

  initializeUploaderBar: () => {
    const { isUploaderBarVisible, resetUploaderBar } = get()
    if (!isUploaderBarVisible) {
      resetUploaderBar()
    }
    set({ stopped: false })
    get().showUploaderBar()
  },

  cancelUpload: () => {
    set({ stopped: true, isUploadComplete: true })
    get().resetUploaderBar()
  },

  resetPartUploaderBar: (weight, uploadedWeight) => {
    set((state) => {
      const uploadedFiles = state.uploadedFiles - uploadedWeight
      const totalUploads = state.totalUploads - weight
      return {
        uploadedFiles,
        totalUploads,
        isUploadComplete: totalUploads === 0 && state.totalCountUploads === 0,
      }
    })
    if (get().totalUploads === 0) {
      get().cancelUpload()
    }
  },

  resetPartUploaderCountBar: (count, uploadedCount) => {
    set((state) => {
      const uploadedCountFiles = state.uploadedCountFiles - uploadedCount
      const totalCountUploads = state.totalCountUploads - count
      return {
        uploadedCountFiles,
        totalCountUploads,
        isUploadComplete: state.totalUploads === 0 && totalCountUploads === 0,
      }
    })
    if (get().totalCountUploads === 0) {
      get().cancelUpload()
    }
  },

  stopPartUpload: (count, weight, uploadedCount, uploadedWeight) => {
    get().resetPartUploaderBar(weight, uploadedWeight)
    get().resetPartUploaderCountBar(count, uploadedCount)
  },
}))

export { useUploaderBarStore }
