const getSpreadPrice = (product) => {
  const spreadOptionData = product.options.find(({ category }) => category.handle === "ADDITIONAL_SPREAD").values[0]

  return {
    price: spreadOptionData.price.discount || spreadOptionData.price,
    currency: spreadOptionData.price.currency,
  }
}

const getSpreadPriceWithoutDiscount = (product) => {
  const spreadOptionData = product.options.find(({ category }) => category.handle === "ADDITIONAL_SPREAD").values[0]

  return {
    price: spreadOptionData.price,
    currency: spreadOptionData.price.currency,
  }
}

export { getSpreadPrice, getSpreadPriceWithoutDiscount }
